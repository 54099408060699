import {config} from "./config";
import axios from "axios";
import bridge from "@vkontakte/vk-bridge";

export const getParams = (obj) => new URLSearchParams(obj).toString();
export function urlParams() {
    let urlForParse = window.location.search;
    return urlForParse.replace("?", '');
}

export const urlP = () => {
    const URLparams = new URLSearchParams(window.location.search);
    const m = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const p of URLparams) {
        m.push(p);
    }
    return Object.fromEntries(m);
};

export const requestAxios = async (props) => {
    try {
        const type = (props?.type) ? props.type : 'get';
        const body = (props?.body) ? props.body : null;
        const configAxios = (props?.config) ? props?.config : {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return new Promise((resolve, reject) => {
            axios[type](props.url, body, configAxios).then((data) => resolve(data?.data)).catch((e) => reject(e));
        });
    } catch (e) {
        console.error(e);
        return new Promise((resolve, reject) => reject(e));
    }
};

export const api = async ({ p, path, body }) => {
    try {
        const url = (path) ? `${config.host}/review/${path}?${getParams(p)}` : `${config.host}/review?${getParams(p)}`
        return new Promise((resolve, reject) => {
            requestAxios({ url, type: 'post', body: { params: urlParams(), ...body } })
                .then((d) => {
                    resolve(d)
                }).catch((e) => {
                reject({status: false, error: e})
            })
        })
    } catch (e) {
        return new Promise((reject) => {
            reject({status: false, error: e})
        })
    }
}

export const setHash = async (route) => {
    try {
        return new Promise((resolve, reject) => {
            bridge.send("VKWebAppSetLocation", {"location": route}).then(result => {
                (result.result === true) ? resolve({status: true}) : resolve({status: false});
            }).catch(e => {
                reject(e)
            });
        });
    } catch (e) {
        return new Promise((reject) => {
            reject(e)
        });
    }
}

const url = new URL( window.location.href )

export const manageUrl = async (type) => {
    try {
        if (type === 'location') {
            return new Promise((resolve) => {
                let hash_array = url.hash.replace('#','').split('=')
                const finalCond = (url.hash !== '') ? {[hash_array[0]]:hash_array[1]} : 0;
                resolve({hash: finalCond})
            });
        } else {
            return new Promise((resolve) => {
                resolve({url: url.toJSON()})
            });
        }
    } catch (e) {
        return new Promise((reject) => {
            reject(e)
        });
    }
}
