import './App.css';
import {Content} from "./Content";
import {AdaptivityProvider, ConfigProvider, usePlatform} from "@vkontakte/vkui";
import '@vkontakte/vkui/dist/vkui.css';
import {useEffect, useState} from "react";
import {api, manageUrl, setHash, urlP} from "./utils";
import bridge from "@vkontakte/vk-bridge";

const App = () => {
    const params = urlP()
    const [user, setUser] = useState(null);
    const [friends, setFriends] = useState(null);
    const [selectedUser, setSelectedUser] = useState(0);
    const [users, setUsers] = useState([])
    const [fetchedUsers, setFetchedUsers] = useState(null);
    const [fetchedUsersForReviews, setFetchedUsersForReviews] = useState(null);
    const [token, setToken] = useState(null);
    const [reviews, setReviews] = useState([]);
    const [renderReviews, setRenderReviews] = useState(false);
    const [onboarding, setOnboarding] = useState('');
    const [settingsData, setSettingsData] = useState('');
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [needUpdate, setNeedUpdate] = useState(false);

    const platform = usePlatform();

    useEffect(() => {
        console.log("platform", platform)
    }, [platform])

    useEffect(() => {
        bridge.send('VKWebAppShowBannerAd', {
            banner_location: 'bottom'
        })
            .then((data) => {
                if (data.result) {
                    // Баннерная реклама отобразилась
                    console.log("VKWebAppShowBannerAd", data.result)
                }
            })
            .catch((error) => {
                // Ошибка
                console.log(error);
            });
    }, [])

    useEffect(() => {
        const keys = [
            'isOnboardingComplete',
            'settings'
        ];

        bridge.send('VKWebAppStorageGet', {
            keys })
            .then((data) => {
                if (data?.keys && data.keys.length) {
                    console.log('VKWebAppStorageGet', data.keys)
                    const [isOnboardingComplete, settings] = data.keys
                        .filter((key, index) => keys[index] === key.key)
                        .map((item) => item?.value);
                    setOnboarding(isOnboardingComplete)
                    setSettingsData(settings)
                }
            })
            .catch((error) => {
                // Ошибка
                console.error(error);
            });
    }, [])

    useEffect(() => {
        console.log('onboarding', onboarding);
    }, [onboarding]);

    useEffect(() => {
        if (token) {
            bridge.send('VKWebAppCallAPIMethod', {
                method: 'friends.get',
                params: {
                    fields: 'photo_200',
                    v: '5.151',
                    access_token: token
                }})
                .then((data) => {
                    if (data?.response) {
                        setFriends(data.response)
                        // Метод API выполнен
                    }
                })
                .catch((error) => {
                    // Ошибка
                    console.error(error);
                });
        }
    }, [token]);

    useEffect(() => {
        setHash(`id=${selectedUser}`)
            .catch(e => console.error(e));
    }, [selectedUser]);

    useEffect(() => {
        manageUrl('location').then((result) => {
            console.log('location', result)
            if (result?.hash) {
                const { id } = result.hash;
                const validatedInt = parseInt(id);
                if (validatedInt) {
                    setSelectedUser(validatedInt);
                }
            }
        }).catch((e) => console.error(e))
    }, [])

    useEffect(() => {
        console.log('fetchedUsersForReviews', fetchedUsersForReviews);
    }, [fetchedUsersForReviews])

    useEffect(() => {
        console.log('reviews', reviews);
        if (!reviews.length && selectedUser) {

        }
        if (reviews.length) {
            let userIds = [];
            reviews.forEach((i) => {
                userIds.push(i.author_id)
            });
            userIds = userIds.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            console.log('userIds', userIds);
            bridge.send('VKWebAppGetUserInfo', {
                request_id: "reviews",
                user_ids: userIds.join(','),
            })
                .then((data) => {
                    console.log("VKWebAppGetUserInfo reviews", data)
                    if (data?.result) {
                        //console.log('VKWebAppGetUserInfo reviews', data.result)
                        const reduced = data.result.reduce((a, b) => ({ ...a, [b.id]: b }), {})
                        setFetchedUsersForReviews(reduced)
                        // Метод API выполнен
                    } else if (data?.id) {
                        setFetchedUsersForReviews({ [data.id]: data })
                    }
                })
                .catch((error) => {
                    // Ошибка
                    console.error(error);
                });
        }
    }, [reviews])

    useEffect(() => {
        if (selectedUser) {
            console.log('selectedUser', selectedUser);
            bridge.send('VKWebAppGetUserInfo', {
                request_id: "profile",
                user_id: selectedUser
            })
                .then((data) => {
                    console.log('VKWebAppGetUserInfo', data)
                    if (data.id) {
                        setFetchedUsers({ [data.id]: data })
                    }
                })
                .catch((error) => {
                    // Ошибка
                    console.error(error);
                });
        }
    }, [selectedUser])

    useEffect(() => {
        if (user) {
            const temp = users;
            temp.push(user.user_id);
            setUsers(temp)
        }
    }, [user])

    useEffect(() => {
        if (selectedUser) {
            const temp = users;
            temp.push(selectedUser);
            setUsers(temp)
        }
    }, [selectedUser])

    useEffect(() => {
        if (params.vk_access_token_settings === 'friends') {
            bridge.send('VKWebAppGetAuthToken', {
                app_id: 7636941,
                scope: 'friends'
            })
                .then( (data) => {
                    if (data.access_token) {
                        setToken(data.access_token)
                    }
                })
                .catch( (error) => {
                    // Ошибка
                    console.error(error);
                });
        }
    }, [])

    useEffect(() => {
        setSelectedUser(parseInt(params.vk_user_id))
    }, [])

    useEffect(() => {
        console.log('user', user);
    }, [user])

    useEffect(() => {
        if (selectedUser) {
            api({ path: 'profile', p: { act: 'get_user', user_id: selectedUser }})
                .then((r) => {
                    if (r?.error) {
                        console.error(r)
                        return
                    }
                    console.log('get_user', r);
                    setUser(r);
                    setNeedUpdate(false);
                })
                .catch((e) => console.error(e));
        }
    }, [selectedUser, needUpdate])

    useEffect(() => {
        if (selectedUser) {
            api({ path: 'reviews', p: { act: 'get' }, body: { to_id: selectedUser }})
                .then((r) => {
                    if (r?.error) {
                        console.error(r)
                        return
                    }
                    setReviews(r);
                    setRenderReviews(false)
                })
                .catch((e) => console.error(e));
        }
    }, [selectedUser, renderReviews])

    useEffect(() => {
        if (isProfileLoading) {
            const timerId = setTimeout(() => setIsProfileLoading(false), 1000);

            return () => clearTimeout(timerId);
        }
    }, [isProfileLoading])

  return (
    <div className="App">
        <ConfigProvider>
            <AdaptivityProvider>
                <Content user={user}
                         setUser={setUser}
                         friends={friends}
                         fetchedUsers={fetchedUsers}
                         fetchedUsersForReviews={fetchedUsersForReviews}
                         reviews={reviews}
                         selectedUser={selectedUser}
                         setSelectedUser={setSelectedUser}
                         setRenderReviews={setRenderReviews}
                         onboarding={onboarding}
                         setToken={setToken}
                         token={token}
                         isProfileLoading={isProfileLoading}
                         setIsProfileLoading={setIsProfileLoading}
                         setNeedUpdate={setNeedUpdate}
                />
            </AdaptivityProvider>
        </ConfigProvider>
    </div>
  );
}

export default App;
