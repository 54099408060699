import {
  AppRoot,
  Avatar,
  Button,
  Cell,
  FormItem,
  FormLayout, FormStatus,
  Gradient,
  Group, IconButton, Input, ModalCard, ModalPage, ModalPageHeader, ModalRoot,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Placeholder, Spinner,
  SplitCol,
  SplitLayout,
  Textarea,
  Title, Tooltip,
  View,
} from "@vkontakte/vkui";
import { api, urlP } from "./utils";
import { Rating } from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import "./App.css";
import {Icon24HomeHeartOutline, Icon24SearchOutline, Icon24TrashSmileOutline} from "@vkontakte/icons";
import bridge from "@vkontakte/vk-bridge";

export const Content = (
{
  user,
  setUser,
  friends,
  fetchedUsers,
  fetchedUsersForReviews,
  reviews,
  selectedUser,
  setSelectedUser,
  onboarding,
  setRenderReviews,
    setToken,
    token,
    isProfileLoading,
    setIsProfileLoading,
  setNeedUpdate,
}) => {
  const params = urlP();
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(3);
  const [activePanel, setActivePanel] = useState("loading");
  const [activeModal, setActiveModal] = useState('');
  const [onboardingState, setOnboardingState] = useState(0);
  const [panelState, setPanelState] = useState(0);

  //const platform = usePlatform();

  useEffect(() => {
    setPanelState(1)
  }, []);

  useEffect(() => {
    const delayed = () => {
      if (panelState === 1) {
        console.log('onboarding', onboarding);
        if (!onboarding.length) {
          setActivePanel('onboarding')
        } else {
          setActivePanel('main')
        }
      }
    }
    const timerId = setTimeout(delayed, 1000);

    return () => clearTimeout(timerId);
  }, [onboarding, panelState])

  useEffect(() => {
    if (onboardingState >= 3) {
      bridge.send('VKWebAppStorageSet', {
        key: 'isOnboardingComplete',
        value: 'complete'
      })
          .then((data) => {
            if (data.result) {
              console.log('VKWebAppStorageSet', data.result)
              // Значение переменной задано
            }
          })
          .catch((error) => {
            // Ошибка
            console.error('VKWebAppStorageSet', error);
          });
    }

  }, [onboardingState])

  const Modal = () => {
    const [name, setName] = useState('');
    const regexp = () => new RegExp(name.toLowerCase());
    const isFounded = (value) => value.match(regexp())
    const filterFunc = (item) => {
      if (!name.length) return true
      if (name.length) {
        const fullName = item.first_name.toLowerCase() + " " + item.last_name.toLowerCase();
        const fullNameReversed = item.last_name.toLowerCase() + " " + item.first_name.toLowerCase();
        const firstMatch = isFounded(fullName);
        const secondMatch = isFounded(fullNameReversed);
        return (firstMatch) ? firstMatch : (secondMatch) ? secondMatch : false;
      }
    }
    const FriendsSearchGroup = () => {
      const friendsArray = (friends && friends?.items && friends.items.length) ? friends.items.filter(filterFunc) : null;
      if (!friendsArray) {
        return <Placeholder.Container>
          <Placeholder.Text>
            Не удалось получить друзей. Возможно, вы не авторизованы.
          </Placeholder.Text>
          <Placeholder.Actions>
            <Button onClick={() => {
              bridge.send('VKWebAppGetAuthToken', {
                app_id: 7636941,
                scope: 'friends'
              })
                  .then( (data) => {
                    if (data.access_token) {
                      setToken(data.access_token)
                    }
                  })
                  .catch( (error) => {
                    // Ошибка
                    console.error(error);
                  });
            }}>
              Авторизоваться
            </Button>
          </Placeholder.Actions>
        </Placeholder.Container>
      }
      if (friendsArray && !friendsArray.length) {
        return <Placeholder>
          Не удалось найти друзей по этому запросу
        </Placeholder>
      }
      return <Group>
        {friends && friends?.items && friends.items.length ? friendsArray.map((fr, _, array) => {
          return <Cell onClick={() => {
            setIsProfileLoading(true)
            setSelectedUser(fr.id)
            setActiveModal('')
          }} before={<Avatar src={fr.photo_200}/>} key={fr.id}>
            {fr.first_name} {fr.last_name}
          </Cell>
        }) : null}
      </Group>
    }
    const MemoizedGroup = () => useMemo(() => <FriendsSearchGroup/>, [friends, name])

    return <ModalRoot activeModal={activeModal} onClose={() => setActiveModal('')}>
      <ModalPage id="friends">
        <ModalPageHeader>Друзья</ModalPageHeader>
        <FormItem top={'Поиск'} className={'friends-formItem-search'}>
          <Input value={name} onChange={(e) => {
            const regExp = new RegExp('[\[]|[\]]|[\\\\\\\\/]|[\\\\\\\\/]|[\~]|[\`]|[\§]|[\±]|[\|]|[\\^]|[\.]|[\;]|[\:]|[\_]|[\-]|[?]|[\)]|[\(]|[=]|[+]|[\/][\\]|[*]|[&]|[%]|[$]|["]|[!]|[{]|[}]|[<]|[>]|[#]|(script)')
            const regexp2 = new RegExp('[a-zA-Z]|[а-яА-ЯёЁ\\s]')
            const isFine = regexp2.test(e.target.value)
            if (!isFine && e.target.value !== '') return
            setName(e.target.value.replace(regExp, ''))
          }}/>
        </FormItem>
        <MemoizedGroup/>
      </ModalPage>
      <ModalCard id="authError">
        <Placeholder.Container>
          <Placeholder.Text>
            К сожалению, без авторизации вы не сможете пользоваться приложением, оно попросту будет работать с ошибками.
          </Placeholder.Text>
        </Placeholder.Container>
      </ModalCard>
    </ModalRoot>
  }


  const isCommentExist = reviews.filter(
    (i) => i.author_id === parseInt(params.vk_user_id),
  );

  useEffect(() => {
    console.log("user", user);
  }, [user]);
  const activateReviews = async () => {
    api({
      path: "profile",
      p: { act: "allow_reviews" },
      body: { is_allowed: user.is_review_allowed ? 0 : 1 },
    })
      .then((r) => {
        if (r?.error) {
          console.error(r);
          return;
        }
        setUser(r);
      })
      .catch((e) => console.error(e));
  };

  const sendReview = async () => {
    api({
      path: "reviews",
      p: { act: "add" },
      body: { to_id: user.user_id, text: review, rating },
    })
      .then((r) => {
        if (r?.error) {
          console.error(r);
          return;
        }
        setRenderReviews(true);
        setNeedUpdate(true);
      })
      .catch((e) => console.error(e));
  };

  const removeReview = async () => {
    api({
      path: "reviews",
      p: { act: "remove" },
      body: { to_id: user.user_id },
    })
        .then((r) => {
          if (r?.error) {
            console.error(r);
            return;
          }
          setRenderReviews(true);
          setNeedUpdate(true);
        })
        .catch((e) => console.error(e));
  };


  const onBack = () => {
    setSelectedUser(parseInt(params.vk_user_id));
  };

  const ReviewsComponent = () => {
    if (reviews && reviews.length && fetchedUsersForReviews) {
      return <Group>
        {reviews.map((item) => {
          return fetchedUsersForReviews[item.author_id] ? (
              <Cell
                  href={`https://vk.ru/id${item.author_id}`}
                  target={"_blank"}
                  key={item.id}
                  multiline
                  before={
                    <Avatar
                        src={
                          fetchedUsersForReviews[item.author_id].photo_200
                        }
                    />
                  }
                  after={(item.author_id === parseInt(params.vk_user_id)) ? <IconButton
                      onClick={(e) => {
                        e.preventDefault()
                        removeReview()
                      }}
                  ><Icon24TrashSmileOutline/></IconButton> : null}
              >
                <div>
                  <Rating
                      name="size-small"
                      value={item.rating}
                      disabled
                      size="small"
                  />
                </div>
                <div>
                  отзыв от:{" "}
                  {fetchedUsersForReviews[item.author_id].first_name}{" "}
                  {fetchedUsersForReviews[item.author_id].last_name}
                </div>
                <div>{item.text}</div>
              </Cell>
          ) : null;
        })}
      </Group>
    }
    return <Placeholder.Container>
      <Placeholder.Text>
        {(isProfileLoading) ? null : 'Отзывов пока нет'}
      </Placeholder.Text>
    </Placeholder.Container>
  }

  const onSearch = (value) => {
    setSelectedUser(value)
  }

  const SearchInput = ({ su, callback }) => {
    const [value, setValue] = useState('');

    const handleKeyDown = (event) => {
      if (event.key == "." || event.key === "-" || event.key === "+" || event.key === "e" || event.key === "E") {
        event.preventDefault();
        return
      }
      if (event.key === 'Enter' || event.keyCode === 13) {
        console.log('Нажата клавиша Enter');
        callback(value)
      }
    };

    useEffect(() => {
      if (su !== value) {
        setValue(su)
      }
    }, [su]);

    return <Input
        type='number'
        className={"input"}
        value={value}
        after={<IconButton onClick={() => callback(value)}><Icon24SearchOutline/></IconButton>}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          if (!e.target.value) {
            setValue('')
            return
          }
          if (e.target.value === '+') {
            setValue('')
            return;
          }
          const value = e.target.value;
          const regExp = new RegExp('[\+]|[\-]|[e]')

          setValue(parseInt(value.replace(regExp, '')) || '')
        }
        }
    />
  }

  const Profile = () => {
    if (isProfileLoading) {
      return <Group>
        <Placeholder>
          Загрузка...
          <Spinner className={'profile-spinner'}/>
        </Placeholder>
      </Group>
    }
    return (user &&
    user?.user_id &&
    fetchedUsers &&
    fetchedUsers[user.user_id] ? (
        <Gradient mode="tint" to={"top"} className={"profileGradient"}>
          <Avatar
              size={96}
              src={fetchedUsers[user.user_id].photo_200}
          />
          <br />
          <Rating
              name="size-small"
              value={user.rating}
              disabled
              size="small"
          />
          <Title
              style={{ marginBottom: 8, marginTop: 20 }}
              level="2"
              weight="2"
          >
            {fetchedUsers[user.user_id].first_name}{" "}
            {fetchedUsers[user.user_id].last_name}
          </Title>
          {user.user_id === parseInt(params.vk_user_id) ? (
              <Tooltip text={'С помощью этой кнопки вы можете включить отзывы в вашем профиле. Пока отзывы отключены, никто их не сможет оставить.'}
                       isShown={onboardingState === 1}
                       onClose={() => setOnboardingState(2)}>
                <Button
                    size="m"
                    mode="secondary"
                    onClick={() => activateReviews()}
                >
                  {!user.is_review_allowed
                      ? "Включить отзывы"
                      : "Отключить отзывы"}
                </Button>
              </Tooltip>
          ) : null}
          <FormLayout>

            <FormItem>
              <Tooltip text={'В этом блоке отображается ID профиля, если его изменить, вы попадете в чужой профиль и сможете посмотреть отзывы там.'}
                       isShown={onboardingState === 2}
                       onClose={() => setOnboardingState(3)}>
                <SearchInput su={selectedUser} callback={onSearch}/>
              </Tooltip>
            </FormItem>
            <Tooltip text={'Эта кнопка покажет всех ваших друзей, чтобы можно было быстро перейти к ним в профиль и посмотреть отзывы о них.'}
                     isShown={onboardingState === 3}
                     onClose={() => setOnboardingState(4)}>
              <Button onClick={() => setActiveModal('friends')}>
                Мои друзья
              </Button>
            </Tooltip>
          </FormLayout>
        </Gradient>
    ) : <Gradient mode="tint" to={"top"} className={"profileGradient"}>
      <Avatar
          size={96}
      />
      <br />
      <Title
          style={{ marginBottom: 8, marginTop: 20 }}
          level="2"
          weight="2"
      >
        Пользователь не выбран
      </Title>
      <FormLayout>
        <FormItem>
          <SearchInput su={selectedUser} callback={onSearch}/>
        </FormItem>
        <Button onClick={() => setActiveModal('friends')}>
          Мои друзья
        </Button>
      </FormLayout>
    </Gradient>)
  }

  return (
    <AppRoot>
      <SplitLayout modal={<Modal/>}>
        <SplitCol autoSpaced>
          <View activePanel={activePanel}>
            <Panel id="main">
              <PanelHeader before={(params.vk_user_id !== selectedUser.toString()) ? <IconButton onClick={onBack} ><Icon24HomeHeartOutline/> </IconButton>: null}>
                Профиль
              </PanelHeader>
              <Profile/>
              {user &&
              user.user_id !== parseInt(params.vk_user_id) &&
              user.is_review_allowed &&
              !isCommentExist.length ? (
                <FormLayout>
                  <FormItem top={"Оставить отзыв"}>
                    <Rating
                      name="size-small2"
                      value={rating}
                      onChange={(e, newValue) => setRating(newValue)}
                      size="small"
                    />
                    <Textarea
                      className={"input"}
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                    />
                    <Button
                      disabled={review.length > 1400}
                      className={"reviewButton"}
                      onClick={() => sendReview()}
                    >
                      {(review.length > 1400) ? 'Отзыв слишком большой' : 'Отправить отзыв'}
                    </Button>
                  </FormItem>
                </FormLayout>
              ) : (user &&
                  user.user_id !== parseInt(params.vk_user_id) &&
                  !user.is_review_allowed) ? <FormStatus>
                Пользователь не разрешил оставлять отзывы о себе
              </FormStatus>: null}
              <Tooltip text={"Тут отображаются отзывы о профиле."}
                       isShown={onboardingState === 4}
                       onClose={() => setOnboardingState(5)}
              >
                <ReviewsComponent/>
              </Tooltip>
            </Panel>
            <Panel id={'onboarding'}>
              <PanelHeader>
                Отзывы
              </PanelHeader>
              <Group>
                <Placeholder.Container>
                  <Placeholder.Text>
                    В этом приложении вы сможете оставить отзывы в профилях других пользователей.
                  </Placeholder.Text>
                  <Placeholder.Actions>
                    <Button onClick={() => {
                      setOnboardingState(1)
                      setActivePanel('main')
                    }}>
                      Погнали!
                    </Button>
                  </Placeholder.Actions>
                </Placeholder.Container>
              </Group>
            </Panel>
            <Panel id={'welcome'}>
              <Placeholder.Container>
                <Placeholder.Text>
                  Для работы сервиса нужна авторизация.<br/>
                  Так мы сможем показать имена пользователей в приложении,<br/>
                  а также их аватарки.<br/><br/>
                  У себя на сервере мы не храним ваши фотографии и имена.
                </Placeholder.Text>
                <Placeholder.Actions>
                  <Button onClick={() => {
                    bridge.send('VKWebAppGetAuthToken', {
                      app_id: 7636941,
                      scope: 'friends'
                    })
                        .then( (data) => {
                          if (data.access_token) {
                            console.log('data', data);
                            setToken(data.access_token);
                            setPanelState(1);
                          }
                        })
                        .catch( (error) => {
                          // Ошибка
                          console.error(error);
                          setActiveModal('authError')
                        });
                  }}>
                    Авторизоваться
                  </Button>
                </Placeholder.Actions>
              </Placeholder.Container>
            </Panel>
            <Panel id={'loading'}>
              <Placeholder>
                Загрузка
                <Spinner className={'loadingSpinner'}/>
              </Placeholder>
            </Panel>
          </View>
        </SplitCol>
      </SplitLayout>
    </AppRoot>
  );
};
